<template>
  <div class="contact">
    <HypersymbolicNavBar
      active-button="contact"
    />
    <div style="height: 10vh"></div>
    <SectionTitle
      color="#E57F79"
      dark
    >
      Error
    </SectionTitle>
    <div class="pt-10">
      <v-row justify="center" no-gutters>
        <v-col cols="11" xs="11" sm="9" md="7" lg="6" xl="6"
               class="d-flex justify-center">
          <div>
            <h1>Something went wrong!</h1>
            <p class="mb-10">
              Try sending an email to contact at hypersymbolic dot com instead?
            </p>
            <v-btn elevation="0" color="primary" to="/">
              Back to main page
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="$vuetify.breakpoint.smAndDown" style="height: 20vh"></div>
  </div>
</template>

<script>
import HypersymbolicNavBar from '@/components/HypersymbolicNavBar.vue';
import SectionTitle from '@/components/SectionTitle.vue';

export default {
  name: 'ContactFailure',

  components: {
    HypersymbolicNavBar,
    SectionTitle
  }
};
</script>
